<template>
  <div class="com-item">
    <div class="m-title">
      <!-- 我们数字未来的货币 -->
      {{ $t("study.tokenBox1T2") }}
    </div>
    <div class="m-p">
      <!-- PSC是数字化的全球性资金。 -->
      {{ $t("study.tokenBox1P1") }} <br />
      <!-- 这是PSC应用中的货币。 -->
      {{ $t("study.tokenBox1P2") }}
    </div>
    <div class="m-but2" @click="uti.goPath(store.link.study.tokenBox1But)">
      <!-- 获取PSC-Swap  -->
      {{ $t("study.tokenBox1But") }}
    </div>
  </div>
</template>
<script>
import Store from "../../store";
export default {
  data() {
    return {
      uti: Store.uti,
      store: Store.state,
    };
  },
};
</script>

<style lang="less" scoped>
.com-item {
  background-color: #e8f3ff;
  background-image: url("../../assets/images/v4/use/m/pscTokenBox1Bg.png");
  background-size: (166.67 / 3.75vw) (164.33 / 3.75vw);
  background-position: right bottom;
  background-repeat: no-repeat;
  padding-top: (115 / 3.75vw);
  padding-bottom: (52 / 3.75vw);
  box-sizing: border-box;
  text-align: center;
  .m-p {
    margin-top: (22 / 3.75vw);
    padding: 0 (14 / 3.75vw);
  }
  .m-but2 {
    margin-top: (49 / 3.75vw);
  }
}
</style>
